import React from 'react'
import TheLayout from '../../shared/TheLayout'
import * as IMAGES from '../../shared/images'
function Nidcom() {
    return (
        <TheLayout>
            <div>
                <div className="landing">
                    <section id="about-hero">
                        <img className="overlay" src={IMAGES.Overlay} alt="img" />
                        <div className="overlay"></div>
                        <div className="about-hero">
                            <div className="about-hero--words">
                                <div>
                                    <h1>About Nidcom</h1>
                                    <p>We are uniquely suited to the tasks that your own project might require and we are always happy to  be part of any product development journey.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="about-nidcom">
                        <div className="about-nidcom">
                            <div className="about-nidcom-left">
                                <h1>About Nidcom</h1>
                                <p>NIDCOM Solutions is a solutions development company into automation and application
                                support services founded in 2013 and located in Lagos, Nigeria. NIDCOM Solutions is devoted to
                                building efficient enterprise solutions within Fintech and other domains and also help your internal
                                development teams achieve achieve same through guidance, training and Support.
                                </p>
                                <p>
                                    <b>At NIDCOM solutions</b>, we have worked with companies across different industries to build
                                applications like digital lending platforms, digital banking solutions, accounting systems and other
                                customized solutions. We have been involved in the design, development, maintenance, and the support of some of these applications.
                                </p>
                            </div>
                            <div className="about-nidcom-right">
                                <img src={IMAGES.Icon24} alt="img" />
                            </div>
                        </div>
                    </section>
                    <section id="why-nidcom">
                        <div className="why-nidcom">
                            <div className="why-nidcom-left">
                                <img src={IMAGES.Icon25} alt="img" />
                            </div>
                            <div className="why-nidcom-right">
                                <h1>Why Choose Us</h1>
                                <p>Whether you are looking to automate your existing processes or you look at building a product fit for the market, our experience and approach are sure to be a valuable asset.
                                </p>
                                <p><b>Our in-house services include:</b></p>
                                <div className="why-nidcom-right-services">
                                    <div className="why-nidcom-right-service">
                                        <div>
                                            <span><img src={IMAGES.Icon27} alt="img" /></span>
                                            <p>Process Automation</p>
                                        </div>
                                        <div>
                                            <span><img src={IMAGES.Icon26} alt="img" /></span>
                                            <p>Application <br />Management/Support</p>
                                        </div>
                                        <div>
                                            <span><img src={IMAGES.Icon29} alt="img" /></span>
                                            <p>Banklingo</p>
                                        </div>
                                    </div>
                                    <div className="why-nidcom-right-service">
                                        <div>
                                            <span><img src={IMAGES.Icon28} alt="img" /></span>
                                            <p>DEVOPS Implementations <br />and Review</p>
                                        </div>
                                        <div>
                                            <span><img src={IMAGES.Icon30} alt="img" /></span>
                                            <p>Architecture Review</p>
                                        </div>
                                        <div>
                                            <span><img src={IMAGES.Icon31} alt="img" /></span>
                                            <p>DLP</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    {/* <section id="team">
                        <div className="team-con">
                            <div className="team-heading">
                                <h1>Meet Our Team</h1>
                                <p>We are the best team. We help in bringing your dreams into reality.</p>
                            </div>
                            <div className="team">
                                <div className="team-item">
                                    <img src={IMAGES.Icon33} alt="img" />
                                    <div className="team-item-word">
                                        <h4>Segun Owa</h4>
                                        <p>CTO</p>
                                        <div>
                                            <img src={IMAGES.Icon32} alt="img" />
                                            <img src={IMAGES.Icon34} alt="img" />
                                            <img src={IMAGES.Icon35} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <img src={IMAGES.Icon33} alt="img" />
                                    <div className="team-item-word">
                                        <h4>Segun Owa</h4>
                                        <p>CTO</p>
                                        <div>
                                            <img src={IMAGES.Icon32} alt="img" />
                                            <img src={IMAGES.Icon34} alt="img" />
                                            <img src={IMAGES.Icon35} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <img src={IMAGES.Icon33} alt="img" />
                                    <div className="team-item-word">
                                        <h4>Segun Owa</h4>
                                        <p>CTO</p>
                                        <div>
                                            <img src={IMAGES.Icon32} alt="img" />
                                            <img src={IMAGES.Icon34} alt="img" />
                                            <img src={IMAGES.Icon35} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <img src={IMAGES.Icon33} alt="img" />
                                    <div className="team-item-word">
                                        <h4>Segun Owa</h4>
                                        <p>CTO</p>
                                        <div>
                                            <img src={IMAGES.Icon32} alt="img" />
                                            <img src={IMAGES.Icon34} alt="img" />
                                            <img src={IMAGES.Icon35} alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <img src={IMAGES.Icon33} alt="img" />
                                    <div className="team-item-word">
                                        <h4>Segun Owa</h4>
                                        <p>CTO</p>
                                        <div>
                                            <img src={IMAGES.Icon32} alt="img" />
                                            <img src={IMAGES.Icon34} alt="img" />
                                            <img src={IMAGES.Icon35} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* <section id="newsletter">
                        <div className="newsletter">
                            <div className="newsletter-heading">
                                <h1>Newsletter</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget ac quis aliquam arcu lacus.</p>
                            </div>
                            <div className="newsletter-body">
                                <input type="text" />
                                <button>Subscribe</button>
                            </div>
                        </div>
                    </section> */}
                </div>
            </div>
        </TheLayout>
    )
}

export default Nidcom
