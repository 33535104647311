import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles/css/index.css';
import Index from './views/home/Index';
import Contact from './views/auth/Contact';
import { useGlobal } from './services/Context';
import Career from './views/career/Career';
import Nidcom from './views/about/Nidcom';
import Services from './views/about/Services';
import Faq from './views/faq/Faq';
import RequestDemo from './views/auth/RequestDemo';
import Payment from './views/payment/Payment';
function App() {
  const { setmsg, msg } = useGlobal()

  window.addEventListener('offline', () => {
    setmsg("Hello Friend,  You are currently offline please do connect to a stable network to continue ")
  })
  window.addEventListener('online', () => {
    setmsg("Welcome back online")
    setTimeout(() => {
      setmsg("")
    }, 3000);
  })
  const msgStyle = {
    display: msg || msg !== null ? 'block' : 'none'
  }
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Index />} />
        <Route path="/career" exact element={<Career />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/about/nidcom" exact element={<Nidcom />} />
        <Route path="/about/services" exact element={<Services />} />
        <Route path="/faq" exact element={<Faq />} />
        <Route path="/request-demo" exact element={<RequestDemo />} />
        <Route path="/payment" exact element={<Payment/>} />
        <Route path="/" element={<Index />} />

      </Routes>
      {
        msg &&
        <div className="err-msg">
          {msg}
        </div>
      }


    </div>
  );
}

export default App;

