import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../assets/images/BankLingo_Logo 1.svg"
import Logo1 from "../../assets/images/BankLingo.svg"
import * as IMAGES from '../../shared/images'

function Payment() {
    const [active, setactive] = useState(0)
    const [showTokenModal, setshowTokenModal] = useState(false)
    return (
        <div style={{ overflow: 'hidden' }}>
            <header className="header-payment">
                <div className="header header-payment">
                    <div className="header--logo">
                        <div className="header--logo--desktop">
                            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="img" src={Logo} style={{ width: '50px' }} />
                                <img alt="img" src={Logo1} style={{ width: '150px', marginLeft: '10px' }} />
                            </Link>
                        </div>
                        <div className="header--logo--mobile">
                            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="img" src={Logo} style={{ width: '50px' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <section id="payment">
                <div className="payment">
                    <div className="payment-left">
                        <div>
                            <h4>Banklingo Transfer Page</h4>
                            <p>Banking made easy. Bank from any part of Nigeria</p>
                        </div>
                        <div>
                            <h5>Recipient Details</h5>
                            <div className="payment-list">
                                <div className="payment-list-left">
                                    <ul>
                                        <li>Name: </li>
                                        <li>Account number: </li>
                                        <li>Account name: </li>
                                    </ul>
                                </div>
                                <div className="payment-list-right">
                                    <ul>
                                        <li>CITI TRUST LIBERIA</li>
                                        <li>0000000000</li>
                                        <li>NIDCOM Solutions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5>Senders Details</h5>
                            <div className="payment-info">
                                <span className="payment-info-group">
                                    <label>Email</label>
                                    <input type="email" placeholder="example@gmail.com" />
                                </span>
                                <span className="payment-info-group drop-dwn">
                                    <label>Country</label>
                                    <input type="text" placeholder="Amount to send" />
                                    <select>
                                        <option>NGN</option>
                                    </select>
                                </span>
                                <span className="payment-info-group drop-dwn">
                                    <label>Mobile Number</label>
                                    <input type="tel" />
                                    <select>
                                        <option>(+234)</option>
                                    </select>
                                </span>
                            </div>
                            <div>
                                <label>Payment</label>
                            </div>
                            <div className="payment-options">
                                <div className="payment-info-group">
                                    <div>
                                        <div className="payment-option">
                                            <div className="payment-option-header" onClick={() => setactive(0)} >
                                                <input type="radio" value={0} checked={active === 0} onChange={() => setactive(0)} />
                                                <label htmlFor=""> Card</label>
                                                <div className="payment-option-header-cards">
                                                    <span><img src={IMAGES.Icon42} alt="img" /></span>
                                                    <span><img src={IMAGES.Icon43} alt="img" /></span>
                                                    <span><img src={IMAGES.Icon44} alt="img" /></span>
                                                    <span><img src={IMAGES.Icon45} alt="img" /></span>
                                                </div>

                                            </div>
                                            <hr />
                                            {
                                                active === 0 &&
                                                <>
                                                    <div className="payment-option-body">
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            setshowTokenModal(true)
                                                        }}>
                                                            <div>
                                                                <span className="payment-info-group">
                                                                    <label>card</label>
                                                                    <input type="number" placeholder="0000 0000 000000" minLength={12} required />
                                                                    <img src={IMAGES.Icon48} alt="img" />
                                                                </span>
                                                                <div className="payment-info-group-side">
                                                                    <span className="payment-info-group">
                                                                        <label>Expiry Date</label>
                                                                        <input type="text" placeholder="MM/YY" required />
                                                                        <img src={IMAGES.Icon47} alt="img" />
                                                                    </span>
                                                                    <span className="payment-info-group">
                                                                        <label>CVC/CVV</label>
                                                                        <input type="number" placeholder="***" minLength={3} required />
                                                                        <img src={IMAGES.Icon46} alt="img" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button type="submit">Proceed</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                        <div className="payment-option">
                                            <div className="payment-option-header" onClick={() => setactive(1)}>
                                                <input type="radio" value={1} checked={active === 1} onChange={() => setactive(1)}/>
                                                <label htmlFor=""> Account</label>
                                            </div><br/>
                                            {
                                                active === 1 &&
                                                <>
                                                    <hr />
                                                    <div className="payment-option-body">
                                                        <form onSubmit={(e) => {
                                                            e.preventDefault();
                                                            setshowTokenModal(true)
                                                        }}>
                                                            <div>
                                                                <span className="payment-info-group">
                                                                    <label>Bank Name</label>
                                                                    <input type="text" placeholder="Enter bank name" required />
                                                                    <img src={IMAGES.Icon48} alt="img" />
                                                                </span><br />
                                                                <span className="payment-info-group">
                                                                    <label>Account Number</label>
                                                                    <input type="number" placeholder="Enter account number" required />
                                                                    <img src={IMAGES.Icon48} alt="img" />
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <button type="submit">Proceed</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="payment-right">
                        <div className="payment-right-box">
                            <div>
                                <h5>Payment Description</h5>
                                <p>Payment for the purchase of Xyzjdjdjdjdjjdjdjdjdd</p>
                            </div>
                            <div>
                                <div className="payment-list">
                                    <div className="payment-list-left">
                                        <ul>
                                            <li>Name: </li>
                                            <li>Account number: </li>
                                            <li>Account name: </li>
                                        </ul>
                                    </div>
                                    <div className="payment-list-right">
                                        <ul>
                                            <li>CITI TRUST LIBERIA</li>
                                            <li>0000000000</li>
                                            <li>NIDCOM Solutions</li>
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <div className="payment-list">
                                    <div className="payment-list-left">
                                        <ul>
                                            <li>Total: </li>
                                        </ul>
                                    </div>
                                    <div className="payment-list-right">
                                        <ul>
                                            <li>5500 USD</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showTokenModal && <TokenModal setshow={setshowTokenModal} />}
            </section>
        </div>
    )
}

export default Payment

const TokenModal = (props) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        props.setshow(false)
    }
    return (
        <div>
            <div className="overlay"></div>
            <div className="modal-con">
                <div className="modal">
                    <div>
                        <p>An authorization code has been sent to the mobile number tied to your bank account *******4839. Please enter to confirm transaction</p>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <span className="payment-info-group">
                                    <label>Enter authorization code</label>
                                    <input type="text" placeholder="Enter authorization code" required />
                                </span><br />
                            </div>
                            <div className="auth--group--form--submit-btn">
                                <button type="submit" style={{ height: '50px' }} >Continue</button>
                                <br /><br /><br />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}