import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useGlobal } from "../services/Context";
import Logo from "../assets/images/BankLingo_Logo 1.svg"
import Logo1 from "../assets/images/BankLingo.svg"
import Nav from "../assets/images/mobile-nav.svg"
const Header = () => {
   const [togleNav, settogleNav] = useState(false);
   const { currentUser } = useGlobal();

   const toggleNav = () => {
      settogleNav((current) => !current);
   };

   const activeStyl = {
      fontWeight: "bold",
      color: "#77B94E",
   };
   return (
      <header className={window.pageYOffset > 10 ? "header-shadow" : ""}>
         <div className="header">
            <div className="header--logo">
               <div className="header--logo--desktop">
                  <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                     <img alt="img" src={Logo} style={{ width: '50px' }} />
                     <img alt="img" src={Logo1} style={{ width: '150px', marginLeft: '10px' }} />
                  </Link>
               </div>
               <div className="header--logo--mobile">
                  <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
                     <img alt="img" src={Logo} style={{ width: '50px' }} />
                  </Link>
               </div>
            </div>
            <div
               className="header--navlinks--mobile"
               style={{ display: togleNav ? "block" : "none" }}
            >
               <div className="header--navlinks--mobile--">
                  <div className="header--navlinks--mobile__close">
                     <Link to="/request-demo" style={{ fontWeight: 600, color: 'black' }}><p>Request a demo</p></Link>
                     <b onClick={toggleNav}>x</b>
                  </div>
                  <nav>
                     <ul>
                        <li>
                           <div>
                              <NavLink style={({ isActive }) =>
                                 isActive ? activeStyl : undefined
                              } to="/">
                                 Home
                              </NavLink>
                           </div>
                        </li>
                        <li>
                           <div>
                              <NavLink style={({ isActive }) =>
                                 isActive ? activeStyl : undefined
                              } to="/career">
                                 Career
                              </NavLink>
                           </div>
                        </li>
                        <li>
                           <div>
                              <NavLink style={({ isActive }) =>
                                 isActive ? activeStyl : undefined
                              } to="/">
                                 About Us
                              </NavLink>
                           </div>
                        </li>
                        <li>
                           <div>
                              <NavLink style={({ isActive }) =>
                                 isActive ? activeStyl : undefined
                              } to="/contact">
                                 Contact Us
                              </NavLink>
                           </div>
                        </li>
                        <li>
                           <div>
                              <NavLink style={({ isActive }) =>
                                 isActive ? activeStyl : undefined
                              } to="/faq">
                                 Faq
                              </NavLink>
                           </div>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div>
            <div
               className="header--navlinks"
               style={{ width: currentUser ? "68%" : "60%" }}
            >
               <nav>
                  <ul>
                     <li>
                        <NavLink style={({ isActive }) =>
                           isActive ? activeStyl : undefined
                        } to="/career">
                           Career
                        </NavLink>
                     </li>
                     <li>
                        <NavLink style={({ isActive }) =>
                           isActive ? activeStyl : undefined
                        } to="/contact">
                           Contact Us
                        </NavLink>
                     </li>
                     <li>
                        <NavLink style={({ isActive }) =>
                           isActive ? activeStyl : undefined
                        } to="/about/nidcom">
                           About Us
                         </NavLink>
                         {/* <div className="header--navlinks__sublinks career">
                           <ul>
                              <li className="first-child">
                                 <NavLink to="/about/nidcom">
                                    About Nidcom
                                 </NavLink>
                              </li>
                              <li>
                                 <NavLink to="/about/services">
                                    Services
                                 </NavLink>
                              </li>
                           </ul>
                        </div> */}
                     </li>
                     <li>
                        <NavLink style={({ isActive }) =>
                           isActive ? activeStyl : undefined
                        } to="/faq">
                           Faq
                         </NavLink>
                     </li>
                  </ul>
               </nav>
            </div>
            <div className="header--btns">
               <div className="header--btns--desktop">
                  <div className="header--btns--desktop__signin">
                     <Link to="/request-demo">
                        <button>REQUEST A DEMO</button>
                     </Link>
                  </div>
               </div>
            </div>
            <div className="header--btns">
               <div className="header--btns--mobile">
                  <img src={Nav} alt="nav" onClick={toggleNav} />
               </div>
            </div>
         </div>
      </header>
   );
};

export default Header;
