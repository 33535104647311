import React from 'react'
import TheLayout from '../../shared/TheLayout'
import * as IMAGES from '../../shared/images'

function Services() {
    return (
        <TheLayout>
            <div>
                <div className="landing">
                    <section id="about-services">
                    <img className="overlay" src={IMAGES.Overlay} alt="img" />
                        <div className="overlay"></div>
                        <div className="about-hero">
                            <div className="about-hero--words">
                                <div>
                                    <h1>Our Services</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, dapibus mattis vel feugiat erat tortor eleifend.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="services">
                        <div className="services">
                            <div className="service">
                                <div className="service-img">
                                    <img alt="img" src={IMAGES.Icon36} />
                                </div>
                                <div className="service-words">
                                    <h4>Mobile and Web Application</h4>
                                    <p>Data is your most valuable asset.But only if you make the most of it. AFQ Tech data scientists and analysts are skilled at mining, modeling, and extracting value from data. Sourced from a range of sectors and with hands-on experience, they put you in charge and draw on the best knowledge and expertise to help you navigate your way forward.</p>
                                    <button>Read More</button>
                                </div>
                            </div>
                            <div className="service">
                                <div className="service-words">
                                    <h4>WhatsApp Chat Bot</h4>
                                    <p>From automation to advanced analytics and seamless experiences, we can embed AI in business. We'll deliver new operating models and strategic intelligence for smart processes and data-driven decisions.<br /><br />
                                    What, Why and How?
                                    </p>
                                    <button>Read More</button>
                                </div>
                                <div className="service-img">
                                    <img alt="img" src={IMAGES.Icon37} />
                                </div>
                            </div>
                            <div className="service">
                                <div className="service-img">
                                    <img alt="img" src={IMAGES.Icon38} />
                                </div>
                                <div className="service-words">
                                    <h4>DevOps Implementation and Review</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br /><br />
                                        type and scrambled it to make a type specimen book. It has survived not only five centu</p>
                                    <button>Read More</button>
                                </div>
                            </div>
                            <div className="service">
                                <div className="service-words">
                                    <h4>IT Consulting</h4>
                                    <p>From automation to advanced analytics and seamless experiences, we can embed AI in business. We'll deliver new operating models and strategic intelligence for smart processes and data-driven decisions.<br /><br />
                                        What, Why and How?
                                    </p>
                                    <button>Read More</button>
                                </div>
                                <div className="service-img">
                                    <img alt="img" src={IMAGES.Icon39} />
                                </div>
                            </div>
                            <div className="service">
                                <div className="service-img">
                                    <img alt="img" src={IMAGES.Icon40} />
                                </div>
                                <div className="service-words">
                                    <h4>Solutions Architecture Review</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br /><br />
                                        type and scrambled it to make a type specimen book. It has survived not only five centu</p>
                                    <button>Read More</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="newsletter">
                        <div className="newsletter">
                            <div className="newsletter-heading">
                                <h1>Newsletter</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget ac quis aliquam arcu lacus.</p>
                            </div>
                            <div className="newsletter-body">
                                <input type="text" />
                                <button>Subscribe</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </TheLayout>
    )
}

export default Services
