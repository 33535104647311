import React, { useState } from 'react'
import { useGlobal } from '../../services/Context'
import MainImage from "../../assets/images/Untitled-16 1.png"
import Mail from '../../assets/images/undraw_mail_sent_re_0ofv 1.png'
import Location from '../../assets/images/gis_location-poi.svg'
import Call from '../../assets/images/fluent_call-connecting-20-filled.svg'
import Email from '../../assets/images/fluent_mail-24-filled.svg'
import TheLayout from "../../shared/TheLayout";
import ButtonLoader from '../../shared/Loader'

const Contact = () => {
    const { msg, contact, loading } = useGlobal()
    const [user, setuser] = useState({
        fullName: "",
        email: "",
        message: ""
    })
    const [show, setshow] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault()
        contact(user)
            .then(() => setshow(true))

    }
    const handleChange = (event) => {
        setuser({ ...user, [event.target.name]: event.target.value })
    }
    const msgStyle = {
        display: msg ? 'block' : 'none'
    }

    return (
        <div>
            <TheLayout>
                <div className="landing">
                    <div className="auth">
                        <div className="auth--group contact">
                            <div className="auth--group--form register">
                                <form onSubmit={handleSubmit}>
                                    <div className="auth--group--form--heading contact--group--form--heading" >
                                        <h1>Let's Talk</h1>
                                        <p>To request a quote or want to meet up for coffee, contact us directly or fill out the form and we will get back to you promptly.</p>
                                    </div>
                                    <br />
                                    <div>
                                        <div>
                                            <span className="auth--group--form--input-group contact--group--form--input-group">
                                                <label>Full Name</label>
                                                <input type="text" placeholder="Enter full name" name="fullName" onChange={handleChange} required />
                                            </span>
                                            <span className="auth--group--form--input-group contact--group--form--input-group">
                                                <label>Email</label>
                                                <input type="email" placeholder="Enter email" name="email" onChange={handleChange} required />
                                            </span>
                                            <span className="auth--group--form--input-group contact--group--form--input-group">
                                                <label>Your Message</label>
                                                <textarea placeholder="Type something you want" name="message" rows={8} onChange={handleChange} required />
                                            </span>

                                        </div>
                                        <br />
                                        <div className="auth--group--form--submit-btn">
                                            <button type="submit" style={{ height: '50px' }}>{loading?.status ? <ButtonLoader />: "Send Message"}</button>
                                            <br /><br /><br />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="auth--group--img contact--group--img ">
                                <div>
                                    <div>
                                        <img src={MainImage} alt="img" className="contact--img" />
                                    </div>
                                    <div className="auth--group--img__text">
                                        {/* <h3></h3> */}
                                        <ul>
                                            <li><img src={Call} alt="img" /><span>(+234) 812 474 5461</span></li>
                                            <li><img src={Email} alt="img" /><span>support@banklingo.app</span></li>
                                            <li><img src={Location} alt="img" /><span>BANKLINGO BUILDING, ALBERT ADEKOYA, GBAGADA, LAGOS, NIGERIA</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="err-msg" style={msgStyle}>
                            {msg}
                        </div>
                        {
                            show &&
                            <div>
                                <div className="overlay"></div>
                                <SuccessModal setshow={setshow} />
                            </div>
                        }

                    </div>
                </div>
            </TheLayout>
        </div>
    )
}

export default Contact


const SuccessModal = (props) => {
    const handleSubmit = () => {
        props.setshow(false)
    }
    return (
        <div className="modal-con">
            <div className="modal">
                <div>
                    <img src={Mail} alt="img" />
                    <h4>Success</h4>
                    <p>Message has been sent successfully</p>
                    <div className="auth--group--form--submit-btn">
                        <button type="submit" style={{ height: '50px' }} onClick={handleSubmit}>Continue</button>
                        <br /><br /><br />
                    </div>
                </div>
            </div>
        </div>
    )
}
