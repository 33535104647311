import React from 'react'
import * as IMAGES from './images'  
function ButtonLoader() {
    return (
        <div className="button-loader">
            <img src={IMAGES.Loader} alt="loading...." />
        </div>
    )
}

export default ButtonLoader
