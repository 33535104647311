import React, { useState, useEffect } from "react";
import TheLayout from "../../shared/TheLayout";
import { Link } from "react-router-dom";
import * as IMAGES from '../../shared/images'

const Index = () => {
    const [active, setactive] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => {
            if(active >= 0 && active < 3){
                setactive(prev => prev + 1)
            }else{
                setactive(0)
            }

        }, 5000)
        return () => {
            clearInterval(timer)
        }
    },[active])
    return (
        <div>
            <TheLayout>
                <div className="landing">
                    <section id="hero" style={{ padding: '0' }}>
                        <img className="overlay" src={IMAGES.Overlay} alt="img" />
                        <div className="hero">
                            <div className="hero--word">
                                <h1 className="hero--word__heading">
                                    Manage your loan, deposit
                                    and savings products with ease
                            </h1>
                                <p className="hero--word__sub">
                                    Multitenant, cloud based and API driven core banking solution with multicurrency support
                            </p>
                                <Link to="/contact"><button className="hero--word__btn">Get Started</button></Link>
                            </div>
                            <div className="hero--img">
                            </div>
                        </div>
                    </section>
                    <section id="offer">
                        <div className="offer">
                            <div className="offer-item">
                                <img alt="img" src={IMAGES.Icon2} />
                                <div>
                                    <h4>Multi Currency</h4>
                                    <p>Consolidated Report Available</p>
                                </div>
                            </div>
                            <div className="offer-item">
                                <img alt="img" src={IMAGES.Icon1} />
                                <div>
                                    <h4>API Driven</h4>
                                    <p>Cloud Based Platform</p>
                                </div>
                            </div>
                            <div className="offer-item">
                                <img alt="img" src={IMAGES.Icon4} />
                                <div>
                                    <h4>Digital Lending</h4>
                                    <p>Loan Origination Process</p>
                                </div>
                            </div>
                            <div className="offer-item">
                                <img alt="img" src={IMAGES.Icon3} />
                                <div>
                                    <h4>24 / 7 Support</h4>
                                    <p>Dedicated Support</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="experience">
                        <div className="experience">
                            <div className="experience--heading">
                                <h1>Creating Extraordinary Customer Experience</h1>
                                <p>The platform was developed with some features to make the user experience smooth and your make you support your customers with ease</p>
                            </div>
                            <div className="experience--body">
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon6} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">01</h1>
                                    </div>
                                    <div>
                                        <h4>Automated <br /> Backups</h4>
                                        <p>Minimum maintenance Backup and recovery procedures Optimal interoperability to core banking systems Full featured administration.</p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon7} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">02</h1>
                                    </div>
                                    <div>
                                        <h4>Hosting on <br /> the Cloud</h4>
                                        <p>BankLingo service subscription provides you with access to hosting on the cloud.</p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon8} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">03</h1>
                                    </div>
                                    <div>
                                        <h4>Sandbox <br /> Environment</h4>
                                        <p>There is a provision for an
                                        environment for training. You can also clone from
                                        production environment to this sandbox environment for
                                        Simulations and training.</p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon9} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">04</h1>
                                    </div>
                                    <div>
                                        <h4>Communication</h4>
                                        <p>Communications between various components of
                                        BankLingo are well protected. Security is one of the
                                        important aspect of the application. We have - SSL
                                        implementation across Client – Server.</p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon5} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">05</h1>
                                    </div>
                                    <div>
                                        <h4>Web Based <br /> Application</h4>
                                        <p>The administration tool is a
                                        Web based portal with support for modern browsers like
                                        google chrome and Microsoft IE/Edge.</p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon12} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">06</h1>
                                    </div>
                                    <div>
                                        <h4>Latest Software and <br /> Hardware Updates</h4>
                                        <p>The software
                                        and hardware are constantly being managed by the
                                        BankLingo team to better support all customers. Patches will be deployed regularly.
                                        </p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon11} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">07</h1>
                                    </div>
                                    <div>
                                        <h4>REST APIs</h4>
                                        <p>You can develop custom solutions
                                        around BankLingo and have a programmatic access
                                        to the cre functionality using the standard APIs
                                        provided.
                                        </p>
                                    </div>
                                </div>
                                <div className="experience--body-point">
                                    <div>
                                        <div className="experience--body-point-img">
                                            <img alt="img" src={IMAGES.Icon10} />
                                        </div>
                                        <h1 className="experience--body-point-numbering">08</h1>
                                    </div>
                                    <div>
                                        <h4>Integration to Local <br /> Service Providers</h4>
                                        <p>BankLingo
                                        existing integrations to local service providers for BVN
                                        validation, Disbursment providers, Account validation,
                                        Credit Check and card services.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="client">
                        <div className="client">
                            <div className="client--heading">
                                <h1>Powering growth for amazing businesses</h1>
                            </div>
                            <div className="client--body">
                                <div className={`client--body-point ${active === 0 ? 'active' : ''}`}>
                                    <div className="client--body-point-img">
                                        <img alt="img" src={IMAGES.Icon49} />
                                    </div>
                                </div>
                                <div className={`client--body-point ${active === 1 ? 'active' : ''}`}>
                                    <div className="client--body-point-img">
                                        <img alt="img" src={IMAGES.Icon50} />
                                    </div>
                                </div>
                                <div className={`client--body-point ${active === 2 ? 'active' : ''}`}>
                                    <div className="client--body-point-img">
                                        <img alt="img" src={IMAGES.Icon51} />
                                    </div>
                                </div>
                                <div className={`client--body-point ${active === 3 ? 'active' : ''}`}>
                                    <div className="client--body-point-img">
                                        <img alt="img" src={IMAGES.Icon52} />
                                    </div>
                                </div>

                            </div>
                            <div className="client--controls">
                                <span className={`${active === 0 ? 'active' : ''}`}></span>
                                <span className={`${active === 1 ? 'active' : ''}`}></span>
                                <span className={`${active === 2 ? 'active' : ''}`}></span>
                                <span className={`${active === 3 ? 'active' : ''}`}></span>
                            </div>
                        </div>
                    </section>
                    <section id="lending">
                        <div className="lending">
                            <div className="lending--heading">
                                <h1>How Lending Works?</h1>
                                <p>BankLingo’s Digital-Lending-Platform (DLP) offers operating capabilities to support day-to-day execution of lending process. We have built APIs to support end-to-end the loan application workflow from product setup to customer’s loan application and helping the business make all right decisions when it comes to credit.</p>
                            </div>
                            <div className="lending--body">
                                <div className="lending--body-left">
                                    <div className="lending--body-left-point">
                                        <div>
                                            <span></span>
                                            <img alt="img" src={IMAGES.Icon18} />
                                        </div>
                                        <div>
                                            <h4>Loan Application</h4>
                                            <p>Review loan application for accuracy, completeness and  also review creditworthiness of the borrowers. If any issue is found, the application will be returned to the borrower or the credit analyst.</p>
                                        </div>
                                    </div>
                                    <div className="lending--body-left-point">
                                        <div>
                                            <span></span>
                                            <img alt="img" src={IMAGES.Icon17} />
                                        </div>
                                        <div>
                                            <h4>Loan Underwriting/Decision Making</h4>
                                            <p>Checks the application taking a variety of components into account: credit score, risk scores, and you can generate your own unique criteria for scoring that is unique to your business or industry.</p>
                                        </div>
                                    </div>
                                    <div className="lending--body-left-point">
                                        <div>
                                            <span></span>
                                            <img alt="img" src={IMAGES.Icon14} />
                                        </div>
                                        <div>
                                            <h4>Loan Disbursement</h4>
                                            <p>Using available channels, fund is disbursed into the borrowers account.</p>
                                        </div>
                                    </div>
                                    <div className="lending--body-left-point">
                                        <div>
                                            <span></span>
                                            <img alt="img" src={IMAGES.Icon15} />
                                        </div>
                                        <div>
                                            <h4>Loan Servicing or Collection</h4>
                                            <p>During the loan origination process, a collection mechanism is also created for the loan which can be account based or an integration to payment gateway.</p>
                                        </div>
                                    </div>
                                    <div className="lending--body-left-point">
                                        <div>
                                            <span></span>
                                            <img alt="img" src={IMAGES.Icon16} />
                                        </div>
                                        <div>
                                            <h4>Reports</h4>
                                            <p>You can generate various about relating to application, disbursement and collections on the platform.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="lending--body-right">
                                    <img alt="img" src={IMAGES.Icon13} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="target">
                        <div className="target">
                            <div className="target--heading">
                                <h1>Target Market</h1>
                                <p>The major target of the product are Commercial banks, MFBs, MFIs, FIs and fintech startups that are into financial services.</p>
                            </div>
                            <div className="target--body">
                                <div className="target--body-point">
                                    <div className="target--body-point-gradient">
                                        <div className="target--body-point-img">
                                            <img alt="img" src={IMAGES.Icon19} />
                                        </div>
                                    </div>
                                    <h4>Commercial <br /> banks</h4>
                                </div>
                                <div className="target--body-point">
                                    <div className="target--body-point-gradient">
                                        <div className="target--body-point-img">
                                            <img alt="img" src={IMAGES.Icon20} />
                                        </div>
                                    </div>
                                    <h4>Micro-Finance <br /> Banks</h4>
                                </div>
                                <div className="target--body-point">
                                    <div className="target--body-point-gradient">
                                        <div className="target--body-point-img">
                                            <img alt="img" src={IMAGES.Icon21} />
                                        </div>
                                    </div>
                                    <h4>Microfinance  <br /> Institution</h4>
                                </div>
                                <div className="target--body-point">
                                    <div className="target--body-point-gradient">
                                        <div className="target--body-point-img">
                                            <img alt="img" src={IMAGES.Icon22} />
                                        </div>
                                    </div>
                                    <h4>Financial <br /> Institutions</h4>
                                </div>
                                <div className="target--body-point">
                                    <div className="target--body-point-gradient">
                                        <div className="target--body-point-img">
                                            <img alt="img" src={IMAGES.Icon23} />
                                        </div>
                                    </div>
                                    <h4>Fintech <br /> Startups</h4>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </TheLayout>
        </div>
    );
};

export default Index;
