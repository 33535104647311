import React, { useContext, useState, useEffect } from 'react'
import { getRequest, postRequest } from './api'

const GlobalContext = React.createContext(null)

export const useGlobal = () => {
    const globalContext = useContext(GlobalContext)
    return globalContext
}



const Context = ({ children }) => {
    const [msg, setmsg] = useState(null);
    const [loading, setloading] = useState({ id: 0, status: false })
    const [countries, setcountries] = useState(null)

    useEffect(() => {
        getCountries()
    }, [])

    const stopLoading = () => {
        setloading({ id: 0, status: false })
    }
    const startLoading = (current) => {
        setloading(current)
    }

    const getCountries = async () => {
        startLoading({ id: 3, status: true })
        const url = "countries";
        await getRequest(url)
            .then((res) => {
                stopLoading()
                setcountries(res)
            })
            .catch((err) => {
                console.log(err)
                setmsg(err.message)
            })
    }
    const requestDemo = async (body) => {
        startLoading({ id: 1, status: true })
        const url = "requestdemo";
        await postRequest(url, body)
            .then((res) => {
                stopLoading()
                setmsg(res?.message)
            })
            .catch((err) => {
                console.log(err)
                setmsg(err.message)
            })
    }
    const contact = async (body) => {
        startLoading({ id: 2, status: true })
        const url = "contactus";
        await postRequest(url, body)
            .then((res) => {
                stopLoading()
                setmsg(res?.message)
            })
            .catch((err) => {
                console.log(err)
                setmsg(err.message)
            })
    }

    const value = {
        setmsg,
        msg,
        loading,
        countries,
        requestDemo,
        contact
    }


    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}

export default Context
