import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'


const TheLayout = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <div>
            <div>
                <Header />
            </div>
            <main>
                {props.children}
            </main>
            <div>
                <Footer />
            </div> 
        </div>
    )
}

export default TheLayout
