import React from 'react'
// import { Link } from 'react-router-dom'
import Logo from "../assets/images/BankLingo_Logo 1.svg"
import Logo1 from "../assets/images/BankLingo.svg"
const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer--part footer--part-first">
                    <img alt="img" src={Logo} style={{ width: '50px' }} />
                    <img alt="img" src={Logo1} style={{ width: '170px', marginLeft:'10px' }} />
                    <p>BankLingo is a cloud based platform for powering
                    financial services. It has the capability of managing both
                    deposit and loan products. The product is developed by
                    NIDCOM solutions
</p>
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="footer--part">
                    <b>ABOUT NIDCOM</b>
                    <ul>
                        <li>Process Automation</li>
                        <li>DEVOPS Implementations and Review</li>
                        <li>Application Management/Support</li>
                        <li>Architecture Review</li>
                        <li>BankLingo</li>
                        <li>DLP</li>
                    </ul>
                </div>
                <div className="footer--part">
                    <b>SERVICES</b>
                    <ul>
                        <li>Mobile and Web Application</li>
                        <li>Develop whatsApp Chat Bot</li>
                        <li>DevOps Implimentation and Review</li>
                        <li>IT Consulting</li>
                        <li>Solutions Architecture Review</li>
                    </ul>
                </div>
                <div className="footer--part footer--part-last">
                <b>CONTACT US</b>
                    <ul>
                        <li>(+234) 812 474 5461</li>
                        <li>support@banklingo.app</li>
                        <li>BANKLINGO BUILDING, ALBERT ADEKOYA, GBAGADA, LAGOS, NIGERIA</li>
                    </ul>
                </div>
            </div>
            <div className="footer--foot">
                <b>All rights reserved | NIDCOM 2021</b>
                <b style={{ float: "right" }}>privacy policy</b>
            </div>
        </footer>
    )
}

export default Footer
