import React from 'react'
import TheLayout from '../../shared/TheLayout'
import { Link } from 'react-router-dom'
import Collapsible from 'react-collapsible';

function Faq() {
    return (
        <TheLayout>
            <div>
                <div className="landing">
                    <section id="faq">
                        <div className="overlay"></div>
                        <div className="faq-hero">
                            <div className="faq-hero--words">
                                <div>
                                    <h1>Frequently Asked Questions</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam, dapibus mattis vel feugiat erat tortor eleifend.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="questions">
                        <div className="questions">
                            <div className="questions-item">
                                <i className="fas fa-angle-down"></i>
                                <Collapsible trigger="What is a Payment Gateway?">
                                    <p>A payment gateway is an ecommerce service that processes online payments for online as well as offline businesses. Payment gateways help accept payments by transferring key information from their merchant websites to issuing banks, card associations and online wallet players.<br /><br />
                                        Payment gateways play a vital role in the online transaction process, which is the realisation of value, and hence are seen as an important pillar of ecommerce.</p>
                                </Collapsible>
                            </div>
                            <div className="questions-item">
                                <i className="fas fa-angle-down"></i>
                                <Collapsible trigger="Do I need to pay to Instapay even when there is no transaction going on in my business?" style={{ background: 'black' }}>
                                    <p>

                                    </p>
                                </Collapsible>
                            </div>
                            <div className="questions-item">
                                <i className="fas fa-angle-down"></i>
                                <Collapsible trigger="What platforms does Instapay payment gateway support?" style={{ background: 'black' }}>
                                    <p>

                                    </p>
                                </Collapsible>
                            </div>
                            <div className="questions-item">
                                <i className="fas fa-angle-down"></i>
                                <Collapsible trigger="Does Instapay provide international payments support?" style={{ background: 'black' }}>
                                    <p>
                            </p>
                                </Collapsible>
                            </div>
                            <div className="questions-item">
                                <i className="fas fa-angle-down"></i>
                                <Collapsible trigger="Is there any setup fee or annual maintainance fee that I need to pay regularly?" style={{ background: 'black' }}>
                                    <p>

                                    </p>
                                </Collapsible>
                            </div>
                        </div>
                    </section>
                    <section id="ask">
                        {/* <img className="overlay" src={IMAGES.Overlay} alt="img" /> */}
                        <div className="ask">
                            <div className="ask-heading">
                                <h1>Can’t find what you’re looking for</h1>
                            </div>
                            <div className="ask-body">
                                <Link to="/contact"><button>Contact Us</button></Link>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </TheLayout>
    )
}

export default Faq
