const baseurl = "https://crossborderdev.banklingo.app/Fintech.CrossBorder/"
let loggedUser = localStorage.getItem('us') || JSON.stringify({ message: '', data: {}, token: '' });
let data = JSON.parse(loggedUser);
let headers = {
    "Content-Type": "application/json",     
};
export const getRequestExternal = async (url) => {
    console.log(data)
    const request = await fetch(`${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    return request.json()
}
export const getRequest = async (url) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "GET",
        headers: headers,
    })
    return request.json()
}
export const postRequest = async (url, body) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
    return request.json()
}

export const patchRequest = async (url, body) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(body)
    })
    return request.json()
}

