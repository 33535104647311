import React from 'react'
import TheLayout from '../../shared/TheLayout'
import * as IMAGES from '../../shared/images'


const Career = () => {
    return (
        <TheLayout>
            <div>
                <div className="landing">
                    <section id="org-hero">
                        <img className="overlay" src={IMAGES.Overlay} alt="img" />
                        <div className="overlay"></div>
                        <div className="org-hero">
                            <div className="org-hero--words">
                                <div>
                                    <h1>Join our team to help reshape
                                    the fintech industry and develop products that is being used by several clients across Africa.
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="org-body">
                        <p>We do not have an opening for you at the moment.
                        However, you can send your resume to pmoffice@banklingo.app and we will be reaching out to you if your service will be needed.
                        </p>
                    </div>
                </div>
            </div>
        </TheLayout>
    )
}
export default Career
